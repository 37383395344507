import { IPool, ITestResource } from "../constants/interfaces";
import { IGetPoolParam, IGetTestResourceParam } from "./api.types";
import { fetchCall } from "./apiUtils";
import { tripsApi } from "./tripsApi";

export const poolApi = tripsApi.injectEndpoints({
  endpoints: (b) => ({
    getTestResources: b.query<ITestResource[], IGetPoolParam>({
      query: (param) => ({
        url: `/v1.1/pools/${param.PoolName}/testassets?isSynthetic=false&excludeSecrets=true&excludeChildResources=true`,
        method: "GET",
      }),
      providesTags: ["SinglePool"],
    }),
    getTestResource: b.query<ITestResource, IGetTestResourceParam>({
      query: (param) => ({
        url: `/v1.1/pools/${param.PoolName}/testassets/${param.TestAssetId}`,
        method: "GET",
      }),
      providesTags: ["TestResource"],
    }),
    getOwnedPools: b.query<IPool[], void>({
      query: () => ({
        url: "/v1.1/pools/my",
        method: "GET",
      }),
      providesTags: ["OwnedPools"],
    }),
    getPoolDetails: b.query<IPool, IGetPoolParam>({
      query: (param) => ({
        url: `/v1.1/pools/${param.PoolName}/details`,
        method: "GET",
      }),
      providesTags: ["PoolDetails"],
    }),
    getPools: b.query<IPool[], void>({
      query: () => ({
        url: "/v1.1/admin/pools",
        method: "GET",
      }),
      providesTags: ["AllPools"],
    }),
  }),
});

export const {
  useGetTestResourcesQuery,
  useGetTestResourceQuery,
  useGetOwnedPoolsQuery,
  useGetPoolDetailsQuery,
  useGetPoolsQuery,
} = poolApi;

/**
 * Updates the list of owners of the pool by pool name.
 * @param poolName - The name of the pool to add an owner.
 * @param upn - The user principal name or security group display name of the owner to add.
 */
export const UpdateOwner = async (
  poolName: string,
  upn: string,
  force: boolean
): Promise<void> => {
  const uri = `/v1.1/pools/${poolName}/owner?force=${force}`;
  const body = upn;

  return await fetchCall("POST", uri, false, body);
};

/**
 * Remove an owner from the list of owners of the pool by pool name.
 * @param poolName - The name of the pool to remove an owner.
 * @param upn - The user principal name or security group display name of the owner to remove.
 */
export const RemoveOwner = async (
  poolName: string,
  upn: string
): Promise<void> => {
  const uri = `/v1.1/pools/${poolName}/owner?ownerName=${upn}`;

  return await fetchCall("DELETE", uri, false);
};

/**
 * Force release of leased test resources in the pool by pool name.
 * @param poolName - The name of the pool.
 */
export const ForceReleaseAllTestAssets = async (
  poolName: string
): Promise<void> => {
  const uri = `/v1.1/pools/${poolName}/leases`;

  return await fetchCall("DELETE", uri, false);
};

/**
 * Force release of a leased test resource in the pool.
 * @param poolName - The name of the pool.
 * @param testAssetId - The identifier of the test asset.
 */
export const ForceReleaseTestAsset = async (
  poolName: string,
  testAssetId: string
): Promise<void> => {
  const uri = `/v1.1/pools/${poolName}/testassets/${testAssetId}/lease`;

  return await fetchCall("DELETE", uri, false);
};

/**
 * Delete a test resource in the pool.
 * @param poolName - The name of the pool.
 * @param testAssetId - The identifier of the test asset.
 */
export const DeleteTestAsset = async (
  poolName: string,
  testAssetId: string
): Promise<void> => {
  const uri = `/v1.1/pools/${poolName}/testassets/${testAssetId}`;

  return await fetchCall("DELETE", uri, false);
};

/**
 * Update the icm connector identifier for the given pool.
 * @param poolName - The name of the pool.
 * @param icmConnectorId - The connector identifier to add to the pool.
 */
export const SetIcmConnectorId = async (
  poolName: string,
  icmConnectorId: string
): Promise<void> => {
  const uri = `/v1.1/pools/${poolName}/icmConnector`;
  const body = icmConnectorId;

  return await fetchCall("PATCH", uri, false, body);
};

export const EnablePool = async (poolName: string): Promise<void> => {
  const uri = `/v1.1/pools/${poolName}/enable`;

  return await fetchCall("POST", uri, false);
};

export const DisablePool = async (poolName: string): Promise<void> => {
  const uri = `/v1.1/pools/${poolName}/disable`;

  return await fetchCall("POST", uri, false);
};
