import { initializeIcons } from "@fluentui/font-icons-mdl2";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { setupStore } from "./redux/configureStore";

// initialize FluentUI Icons *once*
initializeIcons();

// Set up our store
// const persistedState = loadState();
// export const store = setupStore(persistedState);
export const store = setupStore();

// Every time the store changes, we want to save a copy to LocalStorage
// store.subscribe(
//   // We only want it saved max once a second
//   throttle(() => {
//     // We only want to save parts of the state
//     saveState({
//       pools: store.getState().pools,
//       postBuild: store.getState().postBuild,
//     });
//   }, 1000)
// );

ReactDOM.render(
  <>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </>,
  document.getElementById("root")
);
