import { IColumn, MessageBarType } from "@fluentui/react";
import { IUserAnnouncement } from "../../../constants/interfaces";
import ManageUpdateUserAnnouncementAction from "./ManageUpdateUserAnnouncementAction";
import { FormatDateTime } from "../../common/tableUtils";
import ManageDeleteUserAnnouncementAction from "./ManageDeleteUserAnnouncementAction";

export const UserAnnouncementsColumns = (
  deletingUserAnnouncement: boolean,
  updatingUserAnnouncement: boolean,
  setMessage: (message: string | null) => void,
  setMessageType: (messageType: MessageBarType | null) => void,
  onAnnouncementDeleted: (deletedAnnouncement: IUserAnnouncement) => void,
  onDeletingUserAnnouncement: () => void,
  onDeletingUserAnnouncementCompleted: () => void,
  onEditAnnouncement: (announcement: IUserAnnouncement) => void
): IColumn[] => [
  {
    key: "Edit",
    name: "",
    fieldName: "Edit",
    minWidth: 10,
    maxWidth: 10,
    isResizable: true,
    onRender: (item: IUserAnnouncement) => (
      <ManageUpdateUserAnnouncementAction
        userAnnouncement={item}
        updatingAnnouncement={updatingUserAnnouncement}
        onEdit={() => onEditAnnouncement(item)}
      />
    ),
  },
  {
    key: "Title",
    name: "Title",
    fieldName: "Title",
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    onRender: (item: IUserAnnouncement) => <span>{item.Title}</span>,
  },
  {
    key: "Message",
    name: "Message",
    fieldName: "Message",
    minWidth: 250,
    maxWidth: 400,
    isResizable: true,
    onRender: (item: IUserAnnouncement) => item.Message,
  },
  {
    key: "EndDate",
    name: "End Date",
    fieldName: "EndDate",
    minWidth: 70,
    maxWidth: 120,
    isResizable: true,
    onRender: (item: IUserAnnouncement) => FormatDateTime(item.EndDate),
  },
  {
    key: "Deactivate",
    name: "Deactivate",
    fieldName: "Deactivate",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
    onRender: (item: IUserAnnouncement) => (
      <ManageDeleteUserAnnouncementAction
        userAnnouncement={item}
        deletingUserAnnouncement={deletingUserAnnouncement}
        setMessage={setMessage}
        setMessageType={setMessageType}
        onAnnouncementDeleted={onAnnouncementDeleted}
        onDeletingUserAnnouncement={onDeletingUserAnnouncement}
        onDeletingUserAnnouncementCompleted={
          onDeletingUserAnnouncementCompleted
        }
      />
    ),
  },
];
