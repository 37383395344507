import React from "react";
import { MessageBarType } from "@fluentui/react";
import { DeleteUserAnnouncement } from "../../../api/tripsApi";
import ManageReleaseAction from "../../common/ManageReleaseAction";
import {
  IIconButtonProps,
  IItemtoRelease,
  IUserAnnouncement,
} from "../../../constants/interfaces";

interface ManageDeleteUserAnnouncementActionProps {
  userAnnouncement: IUserAnnouncement;
  deletingUserAnnouncement: boolean;
  setMessage: (message: string | null) => void;
  setMessageType: (messageType: MessageBarType | null) => void;
  onAnnouncementDeleted: (deletedAnnouncement: IUserAnnouncement) => void;
  onDeletingUserAnnouncement: () => void;
  onDeletingUserAnnouncementCompleted: () => void;
}

const ManageDeleteUserAnnouncementAction: React.FC<
  ManageDeleteUserAnnouncementActionProps
> = ({
  userAnnouncement,
  deletingUserAnnouncement,
  setMessage,
  setMessageType,
  onAnnouncementDeleted,
  onDeletingUserAnnouncement,
  onDeletingUserAnnouncementCompleted,
}) => {
  const userAnnouncementToRelease: IItemtoRelease = {
    id: `${userAnnouncement.ID}`,
    type: "UserAnnouncement",
    releaseMessage: `UserAnnouncement [${userAnnouncement.ID}] successfully deactivated.`,
    errorMessage: `Error deactivating UserAnnouncement [${userAnnouncement.ID}].`,
    confirmationMessage: `Are you sure you want to deactivate the UserAnnouncement [${userAnnouncement.Title}]?`,
  };

  const iconButtonProps: IIconButtonProps = {
    iconName: "Delete",
    color: "red",
    title: "Deactivate",
  };

  return (
    <ManageReleaseAction
      itemToRelease={userAnnouncementToRelease}
      itemIsReleasing={deletingUserAnnouncement}
      releaseApi={DeleteUserAnnouncement}
      setMessage={setMessage}
      setMessageType={setMessageType}
      onReleasingItem={onDeletingUserAnnouncement}
      onReleasingItemCompleted={onDeletingUserAnnouncementCompleted}
      itemTags={["UserAnnouncements"]}
      iconButtonProps={iconButtonProps}
      onItemReleased={onAnnouncementDeleted}
    />
  );
};

export default ManageDeleteUserAnnouncementAction;
