import { ITheme, Theme } from "@fluentui/react";
import { DARK_THEME_NAME } from "../constants/constants";

/**
 * Determines if the theme mode is dark theme
 * @param theme the theme
 * @returns true if the theme mode is dark theme
 */
export const isDarkModeTheme = (theme: Theme) => theme.name === DARK_THEME_NAME;

/**
 * A quick helper function to create a border style for dark mode view
 * so that elements like callouts and Topology Cards have more contrast
 *
 * @param theme the current theme
 * @returns A partial style for a border to use in dark mode
 */
export const applyDarkModeBorderStyles = (theme: ITheme) =>
  isDarkModeTheme(theme) && {
    border: `1px solid ${theme.palette.neutralLight}`,
  };
