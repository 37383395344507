import { FontIcon, mergeStyleSets, getTheme } from "@fluentui/react";

interface BooleanIconProps {
  value: boolean;
}

export const BooleanIcon: React.FC<BooleanIconProps> = ({ value }) => {
  const theme = getTheme();
  const classNames = mergeStyleSets({
    true: [{ color: theme.palette.green }],
    false: [{ color: theme.palette.neutralSecondary }],
    stack: { height: "100%" },
  });

  const ariaLabel = value ? "True" : "False";
  const iconName = value ? "BoxCheckmarkSolid" : "BoxMultiplySolid";
  const className = value ? classNames.true : classNames.false;
  return (
    <FontIcon
      aria-label={ariaLabel}
      className={className}
      iconName={iconName}
      role="img"
    />
  );
};
