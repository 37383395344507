import { Icon, IconButton, Separator, Stack, Text } from "@fluentui/react";
import "../../../styles/TopNav.css";
import React, { useState } from "react";
import {
  announcementStackStyles,
  iconStyles,
  messageTextStyles,
  separatorMarginStyle,
  titleTextStyles,
} from "../../../constants/styles";
import { useGetUserAnnouncementsQuery } from "../../../api/tripsApi";
import LoadingSpinner from "../../common/LoadingSpinner";

const UserAnnouncement: React.FC = () => {
  const {
    data: userAnnouncements,
    isLoading,
    isFetching,
  } = useGetUserAnnouncementsQuery();

  const [currentIndex, setCurrentIndex] = useState(0);

  const hasAnnouncements = (userAnnouncements?.length ?? 0) > 0;
  const isSingleAnnouncement = userAnnouncements?.length === 1;
  const isFirstAnnouncement = currentIndex === 0;
  const isLastAnnouncement =
    currentIndex === (userAnnouncements?.length ?? 0) - 1;

  const handlePreviousClick = () => {
    setCurrentIndex(
      (currentIndex - 1 + (userAnnouncements?.length || 0)) %
        (userAnnouncements?.length || 1)
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((currentIndex + 1) % (userAnnouncements?.length || 1));
  };

  if (isLoading) return <LoadingSpinner title={`Loading announcements...`} />;
  if (!hasAnnouncements) return null;

  return (
    userAnnouncements && (
      <>
        <Separator
          styles={separatorMarginStyle("-10px", "-10px", "lightgray")}
        />
        <Stack
          data-testid="announcement-stack"
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
          styles={announcementStackStyles}
        >
          <IconButton
            iconProps={{ iconName: "CaretLeftSolid8" }}
            title="Previous"
            ariaLabel="Previous"
            onClick={handlePreviousClick}
            className={
              userAnnouncements?.length === 1 || currentIndex === 0
                ? "buttonDisabled"
                : "buttonEnabled"
            }
            disabled={isSingleAnnouncement || isFirstAnnouncement}
          />
          <Stack
            horizontal
            verticalAlign="center"
            styles={{ root: { flexGrow: 1 } }}
          >
            <Icon iconName="EventInfo" style={iconStyles} />
            <Text variant="medium" styles={titleTextStyles}>
              {userAnnouncements[currentIndex].Title}:
            </Text>
            <Text variant="medium" styles={messageTextStyles}>
              {userAnnouncements[currentIndex].Message}
            </Text>
          </Stack>
          <IconButton
            iconProps={{ iconName: "CaretRightSolid8" }}
            title="Next"
            ariaLabel="Next"
            onClick={handleNextClick}
            styles={{ root: { color: "black" } }}
            className={
              userAnnouncements?.length === 1 ||
              currentIndex === (userAnnouncements?.length ?? 0) - 1
                ? "buttonDisabled"
                : "buttonEnabled"
            }
            disabled={isSingleAnnouncement || isLastAnnouncement}
          />
        </Stack>
        <Separator
          styles={separatorMarginStyle("-10px", "-10px", "lightgray")}
        />
      </>
    )
  );
};

export default UserAnnouncement;
