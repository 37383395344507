import React, { useEffect } from "react";
import {
  Callout,
  DelayedRender,
  DirectionalHint,
  IconButton,
  ITheme,
  mergeStyleSets,
  Text,
  useTheme,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";

import { applyDarkModeBorderStyles } from "../../utils/TypescriptUtils";

type ICopyIconButtonProps = {
  copyText: string;
};

const getStyles = (theme: ITheme) =>
  mergeStyleSets({
    callout: {
      ...applyDarkModeBorderStyles(theme),
      padding: "10px 12px",
    },
  });

const CopyIconButton: React.FC<ICopyIconButtonProps> = ({ copyText }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const buttonId = useId("callout-button");

  useEffect(() => {
    if (isCalloutVisible) {
      const timeout = setTimeout(() => {
        toggleIsCalloutVisible();
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isCalloutVisible, toggleIsCalloutVisible]);

  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={{
          iconName: "Copy",
          imageProps: { shouldFadeIn: true, sizes: "10" },
        }}
        ariaLabel={"Copy to clipboard"}
        onClick={() => {
          navigator.clipboard.writeText(copyText);
          toggleIsCalloutVisible();
        }}
        data-testid="copy-icon"
        title={"Copy to clipboard"}
      />

      {isCalloutVisible && (
        <Callout
          directionalHint={DirectionalHint.bottomCenter}
          className={styles.callout}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          role="status"
          aria-live="assertive"
        >
          <DelayedRender>
            <Text variant="small">Copied!</Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};

export default CopyIconButton;
