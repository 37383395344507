import { createContext } from "react";

export type UserRoleContextType = {
  isAdmin: boolean;
  isUser: boolean;
  isTester: boolean;
};

export const UserRoleContext = createContext<UserRoleContextType>({
  isAdmin: false,
  isUser: false,
  isTester: false,
});
