import { IPool } from "../constants/interfaces";
import { fetchCall } from "./apiUtils";

/**
 * Get Asset Pool given the pool name
 * @param name - The name of the pool to get.
 */
export const GetAssetPoolByName = async (name: string): Promise<IPool> => {
  const uri = `/v1.1/pools/${name}/details`;
  // const uri = `/v1.1/admin/pool?poolName=${name}`;
  return await fetchCall<IPool>("GET", uri);
};

export const helpersApi = {
  GetAssetPoolByName,
};