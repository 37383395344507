import { ConstrainMode, SelectionMode, Text } from "@fluentui/react";
import React from "react";

import { ExtendedDetailsList } from "../../common/ExtendedDetailsList";
import { LeasedTenantColumns } from "./LeasedTenant.columns";
import { ITDFTenantLeaseGroup } from "../../../api/api.types";
import LoadingSpinner from "../../common/LoadingSpinner";

type ILeasedTenantTableProps = {
  leasedTenant: ITDFTenantLeaseGroup;
  isFetching: boolean;
};

export const LeasedTenantTable: React.FC<ILeasedTenantTableProps> = ({
  leasedTenant,
  isFetching,
}) => {
  const renderTable = () => {
    if (isFetching) return;

    if (leasedTenant) {
      return (
        <>
          <>
            <Text variant="large" style={{ marginTop: "1rem" }}>
              Accounts
            </Text>
            <div
              style={{
                height: "1px",
                backgroundColor: "#ccc",
              }}
            />
          </>

          <ExtendedDetailsList
            items={leasedTenant.Accounts}
            columns={LeasedTenantColumns()}
            selectionMode={SelectionMode.none}
            constrainMode={ConstrainMode.unconstrained}
            exportFileName="LeasedTenantTable"
          />
        </>
      );
    }
  };

  return (
    <>
      {isFetching && (
        <LoadingSpinner
          title={`Loading tenant for ${leasedTenant.TenantId}...`}
        />
      )}

      {renderTable()}
    </>
  );
};
