import React, { useState } from "react";
import { IconButton, MessageBarType, Spinner } from "@fluentui/react";
import { useDispatch } from "react-redux";
import { TagDescription } from "../../constants/types";
import { tripsApi } from "../../api/tripsApi";
import { IItemtoRelease, IIconButtonProps } from "../../constants/interfaces";
import ConfirmationDialog from "./ConfirmationDialog";

interface ManageReleaseActionProps {
  itemToRelease: IItemtoRelease;
  itemIsReleasing: boolean;
  releaseApi: (id: string, ...args: any[]) => Promise<void>;
  setMessage: (message: string | null) => void;
  setMessageType: (messageType: MessageBarType | null) => void;
  onReleasingItem: () => void;
  onReleasingItemCompleted: () => void;
  itemTags: TagDescription[];
  iconButtonProps: IIconButtonProps;
  updatedItem?: any;
  onItemReleased?: (item: any) => void;
  validate?: () => boolean;
  spinnerMessage?: string;
}

const ManageReleaseAction: React.FC<ManageReleaseActionProps> = ({
  itemToRelease,
  itemIsReleasing,
  releaseApi,
  setMessage,
  setMessageType,
  onReleasingItem,
  onReleasingItemCompleted,
  itemTags,
  iconButtonProps,
  updatedItem,
  onItemReleased,
  validate,
  spinnerMessage = "Releasing",
}) => {
  const dispatch = useDispatch();

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [releasingItem, setReleasingItem] = useState<any | null>(null);
  const [isReleasingItem, setIsReleasingItem] = useState(false);

  const showReleaseDialog = (item: any) => {
    setReleasingItem(item);
    setIsDialogVisible(true);
  };

  const createHandleRelease = (
    releaseFunction: (releaseId: string, ...args: any[]) => Promise<void>,
    dispatchInvalidateTags: TagDescription[],
    dispatch: ReturnType<typeof useDispatch>
  ) => {
    return async (
      releaseId: string,
      setMessage: (message: string | null) => void,
      setMessageType: (messageType: MessageBarType | null) => void,
      onReleasing: () => void,
      onReleasingCompleted: () => void,
      setIsReleasing: (isReleasing: boolean) => void,
      ...args: any[]
    ) => {
      setIsReleasing(true);
      onReleasing();
      setMessage(null);
      setMessageType(null);

      if (validate && !validate()) {
        setIsReleasing(false);
        return;
      }

      try {
        await releaseFunction(releaseId, ...args);

        setMessage(itemToRelease.releaseMessage);
        setMessageType(MessageBarType.success);

        dispatchInvalidateTags.forEach((tag) =>
          dispatch(tripsApi.util.invalidateTags([tag]))
        );
        if (onItemReleased && updatedItem) {
          onItemReleased(updatedItem);
        }
      } catch (error) {
        setMessage(`${itemToRelease.errorMessage}: ${error}`);
        console.error(itemToRelease.errorMessage, error);
        setMessageType(MessageBarType.error);
      }

      onReleasingCompleted();
      setIsReleasing(false);
    };
  };

  const handleReleaseItem = createHandleRelease(releaseApi, itemTags, dispatch);

  return (
    <>
      <IconButton
        iconProps={{
          iconName: iconButtonProps.iconName,
          styles: { root: { color: iconButtonProps.color } },
        }}
        title={iconButtonProps.title}
        ariaLabel={iconButtonProps.title}
        onClick={() => showReleaseDialog(itemToRelease)}
        disabled={iconButtonProps.disabled || itemIsReleasing}
      />
      <ConfirmationDialog
        isVisible={isDialogVisible}
        title={`${spinnerMessage} ${itemToRelease.type}`}
        message={itemToRelease.confirmationMessage}
        onConfirm={() => {
          setIsDialogVisible(false);
          if (releasingItem) {
            handleReleaseItem(
              releasingItem.id,
              setMessage,
              setMessageType,
              onReleasingItem,
              onReleasingItemCompleted,
              setIsReleasingItem,
              ...(releasingItem.additionalParams || [])
            );
          }
        }}
        onCancel={() => setIsDialogVisible(false)}
      />
      {isReleasingItem && (
        <Spinner
          label={`${spinnerMessage} ${itemToRelease.type}...`}
          ariaLive="assertive"
        />
      )}
    </>
  );
};

export default ManageReleaseAction;
