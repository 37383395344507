import { IFontStyles, ITextStyles, Separator, Text } from "@fluentui/react";
import React, { CSSProperties } from "react";
import { boldTextStyle, noBackgroundStyle } from "../../constants/styles";

interface ICustomSeparatorProps {
  text?: string;
  textStyle?: ITextStyles;
  separatorStyle?: CSSProperties;
  textVariant?: keyof IFontStyles;
}

export const CustomSeparator: React.FC<ICustomSeparatorProps> = ({
  text,
  textStyle = boldTextStyle,
  separatorStyle = noBackgroundStyle,
  textVariant = "large",
}) => (
  <Separator style={separatorStyle}>
    {text && (
      <Text variant={textVariant} styles={textStyle}>
        {text}
      </Text>
    )}
  </Separator>
);
