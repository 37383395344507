import React from "react";
import { useParams } from "react-router-dom";

import { useGetTestResourceQuery } from "../../api/poolApi";
import { useTitle } from "../../hooks/useTitle";
import { ErrorRenderer } from "../common/ErrorRenderer";
import Layout from "../common/Layout";
import LoadingSpinner from "../common/LoadingSpinner";
import TestResourceHeader from "./TestResourceHeader";
import { authProvider } from "../../auth/authConfiguration";
import { IGetTestResourceParam } from "../../api/api.types";
import { TestResourceTable } from "./TestResourceTable";

interface ITestResourcePageRouteParams {
  poolName: string;
  testAssetId: string;
}

const TestResourcePage: React.FC = () => {
  const currentAccount = authProvider.getAccount();
  const { poolName, testAssetId } = useParams<ITestResourcePageRouteParams>();

  const queryParams: IGetTestResourceParam = {
    PoolName: poolName,
    TestAssetId: testAssetId,
    DeveloperUpn: currentAccount.username,
  };
  const {
    data: testResource,
    isLoading,
    isFetching,
    error,
  } = useGetTestResourceQuery(queryParams);

  const pageTitle = testResource
    ? `Pool : ${poolName} - ${testResource.name}`
    : `Pool : ${poolName} - Loading data for ${testAssetId}`;
  useTitle(pageTitle);

  return (
    <Layout>
      {error && <ErrorRenderer error={error} />}
      {isFetching && (
        <LoadingSpinner
          title={`Loading data for test resouce with name : ${testAssetId} from pool ${poolName}.`}
        />
      )}
      {testResource && (
        <>
          <TestResourceHeader testResource={testResource} loading={isLoading} />
          <TestResourceTable
            testResource={testResource}
            isFetching={isFetching}
          />
        </>
      )}
    </Layout>
  );
};

export default TestResourcePage;
