import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPool } from "../../constants/interfaces";

export interface IPoolsState {
  pools: IPool[];
}

export const initialState: IPoolsState = {
  pools: [] as IPool[],
};

const poolsSlice = createSlice({
  name: "pools",
  initialState,
  reducers: {
    getPoolsDataSuccess(state, action: PayloadAction<IPool[]>) {
      state.pools = action.payload;
    },
  },
});

// export actions
export const { getPoolsDataSuccess } = poolsSlice.actions;

// export the reducer
export default poolsSlice.reducer;
