import { ITDFAccountLease } from "../../../api/api.types";
import { ITDFAccountLeaseExt } from "../../../constants/types";
import { renderDate } from "../../../functions/helpers";
import { IExtendedColumn } from "../../common/ExtendedDetailsList";
import PasswordField from "../../common/PasswordField";

export const LeasedTenantColumns = (): IExtendedColumn[] => {
  return [
    {
      key: "column:AccountUPN",
      name: "Account UPN",
      fieldName: "AccountUpn",
      minWidth: 150,
      maxWidth: 500,
      isRowHeader: true,
      isResizable: true,
    },
    {
      key: "column:AccountType",
      name: "Account Type",
      fieldName: "AccountType",
      minWidth: 100,
      maxWidth: 160,
      isRowHeader: true,
      isResizable: true,
    },
    {
      key: "column:Password",
      name: "Password",
      fieldName: "Password",
      minWidth: 85,
      maxWidth: 85,
      isResizable: false,
      onRender: (account: ITDFAccountLeaseExt) => (
        <PasswordField
          type="account"
          name={account.AccountUpn}
          password={account.Password}
        />
      ),
    },
    {
      key: "column:Expiration",
      name: "Expiration",
      fieldName: "ExpirationUtc",
      minWidth: 100,
      maxWidth: 160,
      isRowHeader: true,
      isResizable: true,
      onRender: (accountLease: ITDFAccountLease) =>
        renderDate(accountLease, "ExpirationUtc"),
    },
    {
      key: "column:MaxExpiration",
      name: "Max Expiration",
      fieldName: "MaxExpirationUtc",
      minWidth: 100,
      maxWidth: 160,
      isRowHeader: true,
      isResizable: true,
      onRender: (accountLease: ITDFAccountLease) =>
        renderDate(accountLease, "MaxExpirationUtc"),
    },
  ];
};
