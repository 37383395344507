import {
  DefaultEffects,
  DetailsList,
  DetailsRow,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IGroup,
  IRenderFunction,
  ScrollablePane,
  ScrollbarVisibility,
  Sticky,
  StickyPositionType,
  TooltipHost,
} from "@fluentui/react";
import { NeutralColors } from "@fluentui/theme";
import React, { useMemo } from "react";

import { IExtendedDetailsListProps } from "./ExtendedDetailsList";
import { generateGroups } from "./tableUtils";

export default function ExtendedDetailsListBase<T extends object>(currentProps: IExtendedDetailsListProps<T>): JSX.Element {
  function onRenderRow(props: IDetailsRowProps | undefined) {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: NeutralColors.gray10 };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  }

  /**
   * If groupingProperties are provided, creates groups by recursively grouping the items in order by the defined properties.
   * So if you define "IsFatal", "TaskName", and "TaskState", the groups will automatically group the items by those properties.
   */
  const groups = useMemo<IGroup[] | undefined>(
    () =>
      currentProps.propertiesToGroupBy ? generateGroups(currentProps.items, currentProps.propertiesToGroupBy) : undefined,
    [currentProps.items, currentProps.propertiesToGroupBy],
  );

  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
      return null;
    }

    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => (
      <TooltipHost {...tooltipHostProps} />
    );

    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender?.({
          ...props,
          onRenderColumnHeaderTooltip,
        })}
      </Sticky>
    );
  };

  return (
    <div style={{ position: "relative", height: "60vh" }}>
      <ScrollablePane
        scrollbarVisibility={ScrollbarVisibility.auto}
        style={{
          boxShadow: DefaultEffects.elevation4,
          backgroundColor: "white",
        }}
      >
        <DetailsList
          onRenderRow={onRenderRow}
          onRenderDetailsHeader={onRenderDetailsHeader}
          groups={groups}
          {...currentProps}
        />
      </ScrollablePane>
    </div>
  );
}
