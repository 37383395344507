import { Breadcrumb, Stack } from "@fluentui/react";
import React from "react";

import { ITestResource } from "../../constants/interfaces";
import Card from "../common/Card";
import GenericHeader from "../common/GenericHeader";
import { CustomSeparator } from "../common/Separators";

interface ITestResourceProps {
  testResource: ITestResource;
  loading: boolean;
}

const TestResourceHeader: React.FC<ITestResourceProps> = ({
  testResource,
  loading,
}) => {
  return (
    <>
      <Breadcrumb
        items={[
          {
            text: `All Pools`,
            key: "pools",
            href: `/Pools`,
          },
          {
            text: testResource.profileName,
            key: "pool",
            href: `/Pools/${testResource.profileName}/testAssets`,
          },
          {
            text: testResource.name,
            key: "testResource",
            isCurrentItem: true,
          },
        ]}
        ariaLabel="Breadcrumbs with a button taking you back to main Pools Page and a label for the current Pool Page."
      />
      <Card tokens={{ maxWidth: 1300 }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          tokens={{ childrenGap: 10 }}
        ></Stack>

        <CustomSeparator text="Test Resource Information" />

        <Stack tokens={{ childrenGap: 20 }}>
          <GenericHeader
            data={testResource}
            fields={[{ field: "name" }, { field: "profileName" }]}
            stackProps={{ horizontal: true, tokens: { childrenGap: 50 } }}
          />
        </Stack>

        <CustomSeparator text="Properties" />

        <Stack tokens={{ childrenGap: 20 }}>
          <GenericHeader
            data={testResource}
            fields={[
              {
                field: "properties",
                nestedField: "AdminUserPrincipalName",
                label: "[Admin] User Principal Name",
              },
            ]}
            stackProps={{ horizontal: true, tokens: { childrenGap: 50 } }}
          />
        </Stack>

        <Stack tokens={{ childrenGap: 20 }}>
          <GenericHeader
            data={testResource}
            fields={[
              {
                field: "properties",
                nestedField: "TenantId",
                label: "TenantId",
              },
              {
                field: "properties",
                nestedField: "TestAssetId",
                label: "TestAssetId",
              },
              {
                field: "properties",
                nestedField: "ExpirationDate",
                label: "Expiration Date",
              },
            ]}
            stackProps={{ horizontal: true, tokens: { childrenGap: 50 } }}
          />
        </Stack>
      </Card>
    </>
  );
};

export default TestResourceHeader;
