import React from 'react';
import {
  Dialog,
  DialogType,
  DialogFooter,
  TextField,
  PrimaryButton,
  DefaultButton
} from '@fluentui/react';

interface ConfirmationWithInputDialogProps {
  isVisible: boolean;
  response: string;
  title?: string;
  subText?: string;
  textFieldLabel?: string;
  onDismiss: () => void;
  onSubmit: () => void;
  onInputChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
}

const ConfirmationWithInputDialog: React.FC<ConfirmationWithInputDialogProps> = ({
  isVisible,
  response,
  title,
  subText,
  textFieldLabel,
  onDismiss,
  onSubmit,
  onInputChange,
}) => {

  return (
    <Dialog
      hidden={!isVisible}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: `${title ? title : 'User Input Required'}`,
        subText: `${subText ? subText : 'Please provide the necessary input to proceed.'}`,
      }}
    >
      <TextField
        label={textFieldLabel}
        value={response}
        onChange={onInputChange}
      />
      <DialogFooter>
        <PrimaryButton onClick={onSubmit} text="Submit" />
        <DefaultButton onClick={onDismiss} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};

export default ConfirmationWithInputDialog;