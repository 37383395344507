import { IColumn, IconButton, MessageBarType } from "@fluentui/react";
import { HydrationTarget } from "../../../constants/interfaces";

export const HydrationTargetColumns = (
    hydrationTargets : HydrationTarget[],
    setHydrationTargets: (hydrationTarget: HydrationTarget[]) => void
): IColumn[] => 
{
    const handleRemoveHydrationTarget = (target: string) => {
        const updatedList = hydrationTargets.filter((t) => t.ProfileName !== target);
        setHydrationTargets(updatedList);
    }

    return [
    {
        key: "Hydration Target Profile",
        name: "Hydration Target Profile",
        fieldName: "Hydration Target Profile",
        minWidth: 175,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: HydrationTarget) => <span>{item.ProfileName}</span>,
    },
    {
        key: "Count",
        name: "Count",
        fieldName: "Count",
        minWidth: 200,
        maxWidth: 400,
        isResizable: true,
        onRender: (item: HydrationTarget) => item.Count,
    },
    {
        key: "Remove",
        name: "Remove",
        fieldName: "Remove",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        onRender: (item: HydrationTarget) => (
        <IconButton
            iconProps={{
                iconName: "Delete",
                styles: { root: { color: "red" } },
            }}
            onClick={() => handleRemoveHydrationTarget(item.ProfileName)}
        />),
    },
    ];
}

export default HydrationTargetColumns