import React from "react";
import { PrimaryButton, Stack, Text } from "@fluentui/react";
import Card from "../../common/Card";
import { Panel, PanelType } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";

interface AdminActionCardProps {
  title: string;
  description: string;
  buttonDisabled?: boolean;
  panelContentComponent?: React.ComponentType<any>;
  panelContentProps?: any;
}

const AdminActionCard: React.FC<AdminActionCardProps> = ({
  title,
  description,
  buttonDisabled = false,
  panelContentComponent: PanelContentComponent,
  panelContentProps,
}) => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const handleButtonClick = () => {
    openPanel();
  };

  return (
    <>
      <Card>
        <Stack tokens={{ childrenGap: 10 }}>
          <Text variant="xLarge">{title}</Text>
          <Text variant="mediumPlus">{description}</Text>
          <PrimaryButton
            disabled={buttonDisabled}
            text={title}
            styles={{ root: { width: "300px" } }}
            onClick={handleButtonClick}
          />
        </Stack>
      </Card>
      {PanelContentComponent && (
        <Panel
          isOpen={isOpen}
          onDismiss={dismissPanel}
          type={PanelType.medium}
          closeButtonAriaLabel="Close"
          headerText={title}
        >
          <PanelContentComponent {...panelContentProps} />
        </Panel>
      )}
    </>
  );
};

export default AdminActionCard;
