import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IRootState } from ".";

export interface IConsoleUserRoleState {
  isUser: boolean;
  isAdmin: boolean;
  isTester: boolean;
}

export interface IAppState {
  apiCallsInProgress: number;
  userRoleState: IConsoleUserRoleState;
}

const initialUserRoleState: IConsoleUserRoleState = {
    isUser: false,
    isAdmin: false,
    isTester: false,
};

export const initialAppState: IAppState = {
  apiCallsInProgress: 0,
  userRoleState: initialUserRoleState
};

const appSlice = createSlice({
  name: "appStatus",
  initialState: initialAppState,
  reducers: {
    beginApiCall(state) {
      state.apiCallsInProgress += 1;
    },
    apiCallFailed(state) {
      state.apiCallsInProgress -= 1;
    },
    apiCallSucceeded(state) {
      state.apiCallsInProgress -= 1;
    },
    userRoleStateUpdated(state, action: PayloadAction<IConsoleUserRoleState>) {
      state.userRoleState = action.payload;
    },
    userRoleStateReset(state) {
      state.userRoleState = initialUserRoleState;
    },
  },
});

export const { beginApiCall, apiCallFailed, apiCallSucceeded, userRoleStateUpdated, userRoleStateReset } = appSlice.actions;

export default appSlice.reducer;

export const selectUserIsAdmin = (state: IRootState): boolean => state.appStatus.userRoleState.isAdmin;
