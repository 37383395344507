import React from "react";
import { useParams } from "react-router-dom";

import { ErrorRenderer } from "../../common/ErrorRenderer";
import Layout from "../../common/Layout";
import LoadingSpinner from "../../common/LoadingSpinner";
import { authProvider } from "../../../auth/authConfiguration";
import { LeasedTenantTable } from "./LeasedTenantTable";
import LeasedTenantHeader from "./LeasedTenantHeader";
import {
  useGetDeveloperTenantLeasesQuery,
  useGetMyTenantLeasesQuery,
} from "../../../api/tripsApi";
import { useLeasesData } from "../../../hooks/useLeasesData";
import { findTenant } from "../../../functions/helpers";

interface ILeasedTenantPageRouteParams {
  tenantId: string;
}

const LeasedTenantPage: React.FC = () => {
  const currentAccount = authProvider.getAccount();
  const { tenantId } = useParams<ILeasedTenantPageRouteParams>();

  const { data: leasedTenants, result: tenantResult } = useLeasesData(
    useGetDeveloperTenantLeasesQuery,
    useGetMyTenantLeasesQuery,
    currentAccount.username,
    true
  );

  const { error, isFetching } = tenantResult;
  const tenant = findTenant(leasedTenants, tenantId);

  return (
    <Layout>
      {error && <ErrorRenderer error={error} />}
      {isFetching && (
        <LoadingSpinner title={`Loading data for tenant : ${tenantId}.`} />
      )}

      {tenant && (
        <>
          <LeasedTenantHeader leasedTenant={tenant} />
          <LeasedTenantTable leasedTenant={tenant} isFetching={isFetching} />
        </>
      )}
    </Layout>
  );
};

export default LeasedTenantPage;
