import { Configuration, ProtocolMode } from "@azure/msal-browser";
import AuthenticationProvider from "./AuthenticationProvider";
import { getEnvironmentConfig } from "../config/environmentConfig";

// settings from Azure Portal
const tenantId = process.env.REACT_APP_TENANT_ID as string;

const { frontendClientId, backendClientId } = getEnvironmentConfig();

export const tripsApiScopes = [
  `https://microsoft.onmicrosoft.com/${backendClientId}/user_impersonation`,
];

const msalConfig: Configuration = {
  auth: {
    clientId: frontendClientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: `${window.location.origin}${process.env.PUBLIC_URL}`,
    protocolMode: ProtocolMode.AAD,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const authProvider = new AuthenticationProvider(msalConfig);
