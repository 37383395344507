import { MessageBar, MessageBarType, Stack, Text } from "@fluentui/react";
import { SerializedError } from "@reduxjs/toolkit";
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/cjs/styles/prism";

import type { ExtendedFetchBaseQueryError } from "../../api/apiUtils";

type IErrorRendererProps = {
  error: ExtendedFetchBaseQueryError | SerializedError | undefined;
};

type ErrorMessage = {
  Message: string;
  MessageDetails: string;
};

function isSerializedError(error: ExtendedFetchBaseQueryError | SerializedError): error is SerializedError {
  const castedError = error as SerializedError;
  return (
    castedError.name !== undefined ||
    castedError.message !== undefined ||
    castedError.stack !== undefined ||
    castedError.code !== undefined
  );
}

export const ErrorRenderer: React.FC<IErrorRendererProps> = ({ error }) => {
  const isDevelopment = process.env.NODE_ENV === "development";

  if (error === undefined) {
    return <React.Fragment />;
  } else if (isSerializedError(error)) {
    return <p>Need to render Serialized Error</p>;
  } else {
    // If the error has the data field, cast it as ErrorMessage, otherwise undefined
    const errorMessage = (error.data as ErrorMessage | undefined)?.Message;

    return (
      <Stack>
        <MessageBar messageBarType={MessageBarType.error} title="Error">
          <Stack tokens={{ childrenGap: 10 }}>
            <Text variant="mediumPlus" style={{ fontWeight: 600 }}>
              {error.status}: {error.statusText}
            </Text>

            {errorMessage && <Text>{errorMessage}</Text>}

            {isDevelopment && <Text>Queried URL: {error.url}</Text>}
          </Stack>
        </MessageBar>

        {isDevelopment && !isSerializedError(error) && (
          <SyntaxHighlighter language={"json"} style={vscDarkPlus} useInlineStyles wrapLongLines>
            {JSON.stringify(error.data, undefined, "\t")}
          </SyntaxHighlighter>
        )}
      </Stack>
    );
  }
};
