import React from "react";
import { IconButton, Spinner } from "@fluentui/react";
import {
  IIconButtonProps,
  IUserAnnouncement,
} from "../../../constants/interfaces";

interface ManageUpdateUserAnnouncementActionProps {
  userAnnouncement: IUserAnnouncement;
  updatingAnnouncement: boolean;
  onEdit: () => void;
}

const ManageUpdateUserAnnouncementAction: React.FC<
  ManageUpdateUserAnnouncementActionProps
> = ({ userAnnouncement, updatingAnnouncement, onEdit }) => {
  const iconButtonProps: IIconButtonProps = {
    iconName: "EditMirrored",
    color: "gray",
    title: "Update",
  };

  return (
    <>
      <IconButton
        iconProps={{
          iconName: iconButtonProps.iconName,
          styles: { root: { color: iconButtonProps.color } },
        }}
        title={iconButtonProps.title}
        ariaLabel={iconButtonProps.title}
        onClick={onEdit}
      />
      {updatingAnnouncement && (
        <Spinner
          label={`Updating ${userAnnouncement.Title}...`}
          ariaLive="assertive"
        />
      )}
    </>
  );
};

export default ManageUpdateUserAnnouncementAction;
