import {
  DatePicker,
  IconButton,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import { FormEvent } from "react";
import {
  cancelButtonStyles,
  userAnnouncementFormStyles,
} from "../../../constants/styles";

interface UserAnnouncementFormProps {
  title: string;
  announcementMessage: string;
  endDate: Date | undefined;
  handleTitleChange: (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => void;
  handleMessageChange: (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => void;
  handleEndDateChange: (date: Date | null | undefined) => void;
  handleSubmit: () => void;
  handleCancel: () => void;
  formMode: "create" | "edit";
}

const UserAnnouncementForm: React.FC<UserAnnouncementFormProps> = ({
  title,
  announcementMessage,
  endDate,
  handleTitleChange,
  handleMessageChange,
  handleEndDateChange,
  handleSubmit,
  handleCancel,
  formMode,
}) => {
  return (
    <div style={userAnnouncementFormStyles}>
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack.Item align="end">
          <IconButton
            iconProps={{ iconName: "Cancel" }}
            ariaLabel="Close form"
            onClick={handleCancel}
            styles={cancelButtonStyles}
          />
        </Stack.Item>
        <Stack.Item>
          <TextField
            label="Title"
            value={title}
            onChange={(event, newValue) => handleTitleChange(event, newValue)}
            required={true}
          />
          <TextField
            label="Message"
            multiline
            rows={2}
            value={announcementMessage}
            onChange={(event, newValue) => handleMessageChange(event, newValue)}
            required={true}
          />
          <DatePicker
            label="End Date (optional)"
            onSelectDate={handleEndDateChange}
            value={endDate !== undefined ? new Date(endDate) : undefined}
            placeholder="Select a date..."
            ariaLabel="Select a date"
          />
        </Stack.Item>
        <PrimaryButton
          text={
            formMode === "create"
              ? "Create Announcement"
              : "Update Announcement"
          }
          onClick={handleSubmit}
          disabled={title.trim() === "" || announcementMessage.trim() === ""}
        />
      </Stack>
    </div>
  );
};

export default UserAnnouncementForm;
