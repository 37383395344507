import { minBy } from "lodash";
import { DateTime, DateTimeOptions } from "luxon";

function inferDate(dateValue: unknown, dateTimeOptions?: DateTimeOptions): DateTime {
  switch (typeof dateValue) {
    case "number":
      return DateTime.fromMillis(dateValue, dateTimeOptions);
    case "string":
      return DateTime.fromISO(dateValue, dateTimeOptions);
  }

  throw new Error("Unable to infer DateTime object");
}

/**
 * Checks if the data is a date to format the date
 * @param data the data
 * @returns the formatted data
 */
export const checkIfDate = <T>(data: T[keyof T]): string => {
  // checking for length greater than 7 because this
  // was converting percent failed into a date
  if (data && typeof data === "string" && data.length > 7 && data.length < 60) {
    if (!isNaN(Date.parse(data))) return getAndFormatDate(data);
  }

  return String(data);
};

export function binByHour<T>(data: T[], dateKey: keyof T, binWindow: number, dataTimeZone: string): Map<number, T[]> {
  const mapping = new Map<number, T[]>();
  const earliestDate = minBy(data, (d) => d[dateKey]);
  if (!earliestDate) throw new Error("Wow, trash!");

  const earliestDateObject = inferDate(earliestDate[dateKey], {
    zone: dataTimeZone,
  });

  // The hour part of the earliest date entry in the provided data,
  // since we expect the first bin to be at the yyy-mm-dd hh of the earliest
  // point, we need to calculate it's "offset" in the range [0, 24)
  const binOffset = earliestDateObject.get("hour");

  data.forEach((dataPoint) => {
    // Get the specified date
    const dataStart: DateTime = inferDate(dataPoint[dateKey], {
      zone: dataTimeZone,
    });

    // Calculate the difference in hours from the current data point
    // to the earliest data point in the data set
    const differenceInHours = (dataStart.toSeconds() - earliestDateObject.toSeconds()) / 60 / 60;

    // Calculate the hour offset as an integer representing the new floored
    // hour part representing the numerical bin to place the data point in
    //    e.g. 8 / (binWindow of 4) = place in bin 2
    const hourOffset = Math.floor(differenceInHours / binWindow) * binWindow;

    // Calculate new date for the data point by setting the hour and zeroing
    // out the minutes, seconds, and milliseconds
    const newDate = earliestDateObject.set({
      hour: binOffset + hourOffset,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    // Modify the data point specified by dateKey to have the newly-binned StartHour
    const newDataPoint = { ...dataPoint, [dateKey]: newDate.toISO() };

    const newDateKey = newDate.toMillis();

    if (mapping.has(newDateKey)) {
      mapping.get(newDateKey)?.push(newDataPoint);
    } else {
      mapping.set(newDateKey, [newDataPoint]);
    }
  });

  return mapping;
}

export const getAndFormatDate = (date: Date | string): string => {
  const newDate = new Date(date);
  return `${newDate.toLocaleString()}`;
};

const dateIsValid = (date: string | Date | null) => {
  if (!date) return false;

  return date instanceof Date || !isNaN(Date.parse(date));
};

/**
 * If the variable is null, it returns null.
 * If it is not, it parses the input as a date.
 *
 * @param date a string representing an ISO date time
 * @param options options for parsing the datetime
 *
 * @returns Date in PST or null depending on the nullish nature of date
 */
export const parseNullishDate = (date: string | null, options?: DateTimeOptions): Date | null => {
  if (!date || !dateIsValid(date)) return null;

  return DateTime.fromISO(date, options).toJSDate();
};

/**
 * If the variable is null, it returns null.
 * If it is not, it parses the input as a datetime.
 *
 * @param date a string representing an ISO date time
 * @param options options for parsing the datetime
 *
 * @returns DateTime in PST or null depending on the nullish nature of datetime
 */
export const parseNullishDateTime = (date: string | null, options?: DateTimeOptions): DateTime | null => {
  if (!date || !dateIsValid(date)) return null;

  return DateTime.fromISO(date, options);
};
