import { SkipToken, skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetDeveloperAccountLeasesQuery,
  useGetDeveloperReservationsQuery,
} from "../api/tripsApi";

type LeaseResourceHook = (
  arg: string | SkipToken
) => ReturnType<typeof useGetDeveloperAccountLeasesQuery>;

type ReservationHook = (
  arg: string | SkipToken
) => ReturnType<typeof useGetDeveloperReservationsQuery>;

export const useLeasesData = (
  developerHook: LeaseResourceHook,
  myHook: LeaseResourceHook,
  upn: string | undefined,
  isCurrentUser: boolean | undefined
) => {
  const devQueryArgs = isCurrentUser ? skipToken : upn ?? skipToken;
  const myQueryArgs = isCurrentUser ? upn ?? skipToken : skipToken;

  const developerDataResult = developerHook(devQueryArgs);
  const myDataResult = myHook(myQueryArgs);

  const result = isCurrentUser ? myDataResult : developerDataResult;
  const data = isCurrentUser ? myDataResult.data : developerDataResult.data;

  return { data, result };
};

export const useLeasesDataForReservation = (
  developerHook: ReservationHook,
  myHook: ReservationHook,
  upn: string | undefined,
  isCurrentUser: boolean | undefined
) => {
  const devQueryArgs = isCurrentUser ? skipToken : upn ?? skipToken;
  const myQueryArgs = isCurrentUser ? upn ?? skipToken : skipToken;

  const developerDataResult = developerHook(devQueryArgs);
  const myDataResult = myHook(myQueryArgs);

  const result = isCurrentUser ? myDataResult : developerDataResult;
  const data = isCurrentUser ? myDataResult.data : developerDataResult.data;

  return { data, result };
};
