import { Dropdown, IDropdownOption } from "@fluentui/react";
import React from "react";

import { IExtendedColumn } from "../ExtendedDetailsList";
import { DropdownFieldFilter, FilterRules, FilterType } from "../filterUtils";
import * as tableUtils from "../tableUtils";

export type IDropDownFilterProps<T> = {
  items: T[];
  column: IExtendedColumn;
  filterRules: FilterRules<T>;
  setFilterRules: React.Dispatch<React.SetStateAction<FilterRules<T>>>;
};

export function DropDownFilter<T>(props: IDropDownFilterProps<T>): JSX.Element {
  const { items, column, filterRules, setFilterRules } = props;
  const key = column.fieldName as keyof T;

  const uniqueValues: string[] = Array.from(
    new Set(
      items.map((item) => {
        const value = item[key];

        if (typeof value === "string") {
          return value as string;
        } else {
          // this handles the case where the column specified for a Dropdown Filter does not have string types.
          return `${value}`;
        }
      }),
    ),
  );

  // sort the dropdown options alphabetically
  uniqueValues.sort();

  const dropdownOptions = tableUtils.arrayToDropdownOptions(uniqueValues);

  const dropdownRule = filterRules[key] as DropdownFieldFilter;

  const dropdowOnChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      // setFilterRules
      const currentOptions = dropdownRule.dropdownFilter;
      const newOptions = option.selected
        ? [...currentOptions, option.key as string]
        : currentOptions.filter((key) => key !== option.key);

      const newFilterRules = { ...filterRules };

      newFilterRules[key] = {
        type: FilterType.DropDown,
        dropdownFilter: newOptions,
      };

      setFilterRules(newFilterRules);
    }
  };

  return (
    <>
      <Dropdown
        placeholder={`Select ${column.name}`}
        label={`${column.name}`}
        options={dropdownOptions}
        selectedKeys={dropdownRule.dropdownFilter}
        onChange={dropdowOnChange}
        multiSelect
      />
    </>
  );
}
