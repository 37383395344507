import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import React from "react";

import { IExtendedColumn } from "../ExtendedDetailsList";
import { BooleanFieldFilter, FilterRules, FilterType } from "../filterUtils";

export type IBooleanFilterProps<T> = {
  column: IExtendedColumn;
  filterRules: FilterRules<T>;
  setFilterRules: React.Dispatch<React.SetStateAction<FilterRules<T>>>;
};

export function BooleanFilter<T>(props: IBooleanFilterProps<T>): JSX.Element {
  const { column, filterRules, setFilterRules } = props;

  const key = column.fieldName as keyof T;
  const booleanRule = filterRules[key] as BooleanFieldFilter;

  const options: IChoiceGroupOption[] = [
    { key: "true", text: `Show ${column.name}` },
    { key: "false", text: `Hide ${column.name}` },
    { key: "neither", text: "Show all", defaultChecked: true },
  ];

  let selectedKey = "";

  if (booleanRule.booleanFilter === undefined) {
    selectedKey = "neither";
  } else if (booleanRule.booleanFilter) {
    selectedKey = "true";
  } else {
    selectedKey = "false";
  }

  const onBoolChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    const newFilterRules = { ...filterRules };
    let boolFilter: boolean | undefined = undefined;

    switch (option?.key) {
      case "true":
        boolFilter = true;
        break;
      case "false":
        boolFilter = false;
        break;
    }

    newFilterRules[key] = {
      type: FilterType.Boolean,
      booleanFilter: boolFilter,
    };

    setFilterRules(newFilterRules);
  };

  return (
    <>
      <ChoiceGroup options={options} label={column.name} onChange={onBoolChange} selectedKey={selectedKey} />
    </>
  );
}
