import React, { useState } from "react";
import {
  DetailsList,
  Icon,
  Label,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Separator,
  Stack,
} from "@fluentui/react";
import { IUserAnnouncement } from "../../../constants/interfaces";
import { separatorMarginStyle } from "../../../constants/styles";
import { UserAnnouncementsColumns } from "./UserAnnouncements.columns";

interface UserAnnouncementsTableProps {
  userAnnouncements: IUserAnnouncement[];
  onEditAnnouncement: (announcement: IUserAnnouncement) => void;
}

export const UserAnnouncementsTable: React.FC<UserAnnouncementsTableProps> = ({
  userAnnouncements,
  onEditAnnouncement,
}) => {
  const [message, setMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<MessageBarType | null>(null);
  const [deletingAnnouncement, setDeletingAnnouncement] =
    useState<boolean>(false);
  const [updatingAnnouncement, setUpdatingAnnouncement] =
    useState<boolean>(false);

  const [announcements, setAnnouncements] = useState(userAnnouncements);

  const handleAnnouncementDeleted = (
    deletedAnnouncement: IUserAnnouncement
  ) => {
    setAnnouncements(
      announcements.filter(
        (announcement) => announcement.ID !== deletedAnnouncement.ID
      )
    );
  };

  const handleDeletingAnnouncement = () => {
    setDeletingAnnouncement(true);
  };

  const handleDeletingAnnouncementCompleted = () => {
    setDeletingAnnouncement(false);
  };

  const announcementsColumns = UserAnnouncementsColumns(
    deletingAnnouncement,
    updatingAnnouncement,
    setMessage,
    setMessageType,
    handleAnnouncementDeleted,
    handleDeletingAnnouncement,
    handleDeletingAnnouncementCompleted,
    onEditAnnouncement
  );

  return (
    <>
      <Stack>
        <Label style={{ marginTop: "10px" }}>
          <Stack horizontal verticalAlign="center">
            <Icon
              iconName="FlickLeft"
              style={{ color: "gray", paddingRight: "4px" }}
            />
            <span>
              List of actives user announcements ({userAnnouncements.length})
            </span>
          </Stack>
        </Label>
        <Separator styles={separatorMarginStyle("-15px", "-5px")} />
      </Stack>
      {message && messageType !== null && (
        <MessageBar
          messageBarType={messageType}
          isMultiline={false}
          onDismiss={() => {
            setMessage(null);
            setMessageType(null);
          }}
        >
          {message}
        </MessageBar>
      )}
      <DetailsList
        items={userAnnouncements}
        columns={announcementsColumns}
        selectionMode={SelectionMode.none}
      />
    </>
  );
};
