import React, { useEffect } from "react";
import {
  Dropdown,
  IDropdownOption,
  Separator,
  Stack,
} from "@fluentui/react";
import { separatorMarginStyle } from "../../../constants/styles";

type ConfigTenantSkuSelectorProps = {
  selectedTenantSkuKey: string | null;
  updatedTenantSkuOptions: IDropdownOption[];
  handleTenantSkuChange: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => void;
  required?: boolean;
  readOnly?: boolean,
};

const ConfigTenantSkuSelector: React.FC<ConfigTenantSkuSelectorProps> = ({
  selectedTenantSkuKey,
  updatedTenantSkuOptions,
  handleTenantSkuChange,
  required = false,
  readOnly = false,
}) => {
    // rerender after the pool fields have been set with details of the pool to update
  useEffect(() => {
  }, [selectedTenantSkuKey]);

  return (
    <Stack>
      <Separator styles={separatorMarginStyle("-15px", "-5px")} />
      <Dropdown
        placeholder="Select an option"
        label="Tenant Sku"
        options={updatedTenantSkuOptions}
        selectedKey={selectedTenantSkuKey}
        onChange={handleTenantSkuChange}
        required={required}
        disabled={readOnly}
        styles={{
          dropdown: {
            width: "300px",
          },
        }}
      />
    </Stack>
  );
};

export default ConfigTenantSkuSelector;
