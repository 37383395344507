import { IStackProps, Stack } from "@fluentui/react";
import React from "react";

type ILayoutProps = IStackProps;

const Layout: React.FC<ILayoutProps> = (props) => {
  return (
    <Stack tokens={{ padding: 25, childrenGap: 10, maxWidth: 3000 }} {...props}>
      {props.children}
    </Stack>
  );
};

export default Layout;
